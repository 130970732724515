<template>
  <div>({{ $trans("id") }}: {{ product.id }}) {{ product.name }}</div>
</template>
<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
};
</script>
