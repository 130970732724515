var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{key:_vm.item.id,staticClass:"table-row",class:{
    'v-data-table__mobile-table-row': !_vm.$vuetify.breakpoint.mdAndUp,
  },on:{"click":function($event){$event.stopPropagation();return _vm.clickRow(_vm.item)},"mouseover":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false}}},[_c('td',{staticClass:"pt-2 text-center",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__checkbox-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"}):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('v-simple-checkbox',{attrs:{"ripple":false,"value":_vm.isSelected,"color":"primary"},on:{"input":function($event){return _vm.selectOnChange($event)}}})],1)]),_c('td',{staticClass:"pt-2 text-center",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__5-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_c('div',[_vm._v(" "+_vm._s(_vm.$trans("id"))+" ")])]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',{staticClass:"text-left"},[_c('div',[_vm._v(_vm._s(_vm.item.id))])])])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__15-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("payment_date"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_vm._v(" "+_vm._s(_vm._f("dateTime")(_vm.item.paid_at,_vm.$helpers.hourDayMonthAndYearDate))+" ")])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__25-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("client"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[(_vm.item.user)?_c('div',[_c('user-card-details',{attrs:{"details-in-tooltip":"","justify-left":"","clickable":"","push-transition":"","user":_vm.item.user}})],1):_vm._e(),(_vm.item.gdpr_fields)?_c('gdpr-fields-tooltip',{staticClass:"mt-2",attrs:{"gdpr-fields":_vm.item.gdpr_fields}}):_vm._e()],1)]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__15-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("simple_store_product"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[(_vm.item.product)?_c('simple-store-product-card-details',{attrs:{"product":_vm.item.product}}):_vm._e()],1)]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__5-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("price"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_vm._v(" "+_vm._s(_vm._f("money")(_vm.item.price))+" ")])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__25-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("transaction_id"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',[_vm._v(_vm._s(_vm.item.transaction_id))]),(_vm.item.invoice_number)?_c('div',[_vm._v(" "+_vm._s(_vm.$trans("invoice_number"))+": "+_vm._s(_vm.item.invoice_number)+" ")]):_vm._e()])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__manage-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[_c('div',{staticClass:"row-list-buttons white rounded mr-1 mt-2"},[_c('simple-store-transaction-list-buttons',{attrs:{"is-hovered":_vm.isHovered,"item":_vm.item}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }