<template>
  <v-container class="table-filters" fluid>
    <v-row>
      <v-col cols="12" sm="3">
        <calendar-range-input
          v-model="selectedFiltersPaymentDateRange"
          :disabled="disabled"
          :label="$trans('payment_date_range')"
          clearable
          dense
          only-past-dates
        />
      </v-col>
      <v-col cols="12" sm="3">
        <clients-field
          v-model="selectedFiltersUsers"
          :disabled="disabled"
          deletable-chips
          dense
          hide-actions
          multiple
          small-chips
          keep-menu-open-on-select
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="selectedFiltersSearch"
          :disabled="disabled"
          :label="$trans('search')"
          :placeholder="$trans('autocomplete_hint')"
          append-icon="$magnify"
          clearable
          dense
          hide-details
          outlined
          single-line
        />
      </v-col>
    </v-row>
    <v-row class="my-4" justify="center">
      <v-col class="d-flex align-center justify-center" cols="12" md="3" sm="6">
        <v-btn
          :disabled="disabled"
          color="primary"
          outlined
          small
          @click="clearFilters"
        >
          {{ $trans("clear_filters") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sharedListFilters from "@/calendesk/mixins/sharedListFilters";
import ClientsField from "@/views/dashboard/pages/Calendar/components/forms/Booking/ClientsField.vue";
import CalendarRangeInput from "@/lib/calendesk-js-library/components/CalendarRangeInput.vue";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  components: { CalendarRangeInput, ClientsField },
  mixins: [sharedListFilters, sharedActions],
  data() {
    return {
      cacheId: "simple-store-transaction-list-filters",
      selectedFiltersSearch: null,
      selectedFiltersPaymentDateRange: null,
      selectedFiltersUsers: null,
    };
  },
  computed: {
    filters() {
      return {
        selectedFiltersSearch: this.selectedFiltersSearch,
        selectedFiltersPaymentDateRange: this.selectedFiltersPaymentDateRange,
        selectedFiltersUsers: this.selectedFiltersUsers,
      };
    },
  },
  mounted() {
    if (this.$route.query.period) {
      if (this.$route.query.period === "today") {
        this.setToday();
      }

      if (this.$route.query.period === "week") {
        this.setThisWeek();
      }
    }
  },
  methods: {
    setToday() {
      this.selectedFiltersPaymentDateRange = [this.getToday(), this.getToday()];
    },
    setThisWeek() {
      this.selectedFiltersPaymentDateRange = [
        this.$moment().startOf("week").format(this.$helpers.dateFormat),
        this.$moment().endOf("week").format(this.$helpers.dateFormat),
      ];
    },
    getData() {
      const data = {};

      if (this.selectedFiltersSearch) {
        data.query = this.selectedFiltersSearch;
      }

      if (
        this.selectedFiltersPaymentDateRange &&
        this.selectedFiltersPaymentDateRange.length > 0
      ) {
        data.paid_from = this.selectedFiltersPaymentDateRange[0];

        if (this.selectedFiltersPaymentDateRange.length === 2) {
          data.paid_to = this.selectedFiltersPaymentDateRange[1];
        } else {
          data.paid_to = data.paid_from;
        }
      }

      if (this.selectedFiltersUsers && this.selectedFiltersUsers.length > 0) {
        data.user_ids = this.selectedFiltersUsers.map((u) => u.id).join(",");
      }

      return data;
    },
  },
};
</script>
