<template>
  <list-buttons
    :is-hovered="isHovered"
    :extra-actions="extraActions"
    :primary-actions="primaryActions"
    @fireAction="fire"
  />
</template>
<script>
import ListButtons from "@/components/ListButtons.vue";
import simpleStoreActions from "@/calendesk/mixins/simpleStoreActions";

export default {
  components: { ListButtons },
  mixins: [simpleStoreActions],
  props: {
    item: {
      type: Object,
      required: true,
    },
    isHovered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    extraActions() {
      if (this.loggedUserCanDeleteStoreProductTransactions) {
        return [
          {
            action: "delete",
            color: "error",
            icon: "$trash-default",
            title: this.$trans("delete"),
          },
        ];
      }

      return [];
    },
    primaryActions() {
      if (this.loggedUserCanDeleteStoreProductTransactions) {
        return [
          {
            action: "delete",
            color: "error",
            icon: "$trash-default",
            title: this.$trans("delete"),
          },
        ];
      }

      return [];
    },
  },
  methods: {
    fire(action) {
      const cloned = this.$helpers.cloneObject(this.item);
      switch (action) {
        case "delete":
          this.handleDeleteProductTransactions([cloned]);
          break;
      }
    },
  },
};
</script>
